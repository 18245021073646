import { EYaShareActions } from '../../enums/actions.enum';
import { TYaShareActions } from '../actions/yaShare';

interface IYaShareState {
  ready: boolean;
  error: boolean;
}

const initialState: IYaShareState = {
  ready: false,
  error: false,
};

// eslint-disable-next-line default-param-last
export const yaShareReducer = (state = initialState, action: TYaShareActions): IYaShareState => {
  switch (action.type) {
    case EYaShareActions.SetYaShareReady:
      return {
        ...state,
        ready: action.payload,
      };

    case EYaShareActions.SetYaShareLoadError:
      return {
        ...state,
        error: action.payload,
      };
  
    default:
      return state;
  }
};
