import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { productSelectors } from '../store/selector/productSelectors';

export const useSetDocumentTitle = () => {
  const product = useSelector(productSelectors);

  useEffect(() => {
		if (!product) return;

		const { title: productName, brand } = product;
		const brandName = brand?.title ?? '';

		document.title = `Инструкция для ${brandName} ${productName}`
	}, [product])
};
