import { EScaleActions } from '../../enums/actions.enum';
import { ISetScaleAction, ISetStepScaleAction } from '../../interfaces/scale';

export const setScaleAction = (payload: number): ISetScaleAction => ({
	type: EScaleActions.setScale,
	payload,
});

export const setStepScaleAction = (payload: number): ISetStepScaleAction => ({
	type: EScaleActions.setStepScale,
	payload,
});
