import { Dispatch } from 'redux';
import { EYaShareActions } from '../../enums/actions.enum';

const SCRIPT_SRC = 'https://yastatic.net/share2/share.js';

export const setYaShareReady = (payload: boolean) => ({
	type: EYaShareActions.SetYaShareReady,
	payload,
});

export const setYaShareLoadError = (payload: boolean) => ({
	type: EYaShareActions.SetYaShareLoadError,
	payload,
});

export const loadYaShareScriptThunk = () => {
	return async (dispatch: Dispatch<TYaShareActions>) => {
    const isLibLoaded = Boolean(window.Ya?.share2);

    if (isLibLoaded) {
      return;
    }

		const isLoading = document.querySelector(`script[src="${SCRIPT_SRC}"]`);

		if (isLoading) {
			return;
		}

		const script = document.createElement('script');
		script.setAttribute('src', SCRIPT_SRC);
		script.setAttribute('async', '');

		script.onload = () => {
			dispatch(setYaShareReady(true));
		};

		script.onerror = () => {
      dispatch(setYaShareLoadError(true));
			console.error('Error loading Ya.share2 script');
		};

		document.head.appendChild(script);
	};
};

export type TYaShareActions = ReturnType<typeof setYaShareReady> | ReturnType<typeof setYaShareLoadError>;
