import { useEffect, useRef } from 'react';
import { IPosition, ISize } from '../../interfaces/products';

interface IProps {
	colorBorder: string;
	size: ISize;
	position: IPosition;
	scale: number;
}

function ButtonFrame({ position, size, colorBorder, scale }: IProps) {
	const buttonFrameRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		buttonFrameRef.current?.scrollIntoView({
			block: 'center',
			inline: 'center',
			behavior: 'smooth',
		});
	}, [scale, position, size]);

	return (
		<div
			ref={buttonFrameRef}
			style={{
				position: 'absolute',
				border: `2px dotted ${colorBorder}`,
				width: size.x,
				height: size.y,
				left: position.x,
				top: position.y,
			}}
		/>
	);
}

export default ButtonFrame;
