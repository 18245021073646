import { Dispatch } from 'react';
import {
	IFetchProduct,
	IProduct,
	ISetIndexInstructionAction,
	ISetStepAction,
	TProductActions,
} from '../../interfaces/products';
import { productApi } from '../../api';
import { EAccordionActions, EProductActions } from '../../enums/actions.enum';

const setProductAction = (payload: IProduct): IFetchProduct => ({
	type: EProductActions.fetchProduct,
	payload,
});

export const setIndexInstructionAction = (indexInstruction: number): ISetIndexInstructionAction => ({
	type: EAccordionActions.setIndexInstruction,
	payload: indexInstruction,
});
export const setStepAction = (currentStep: number): ISetStepAction => ({
	type: EAccordionActions.setStep,
	payload: currentStep,
});

export const getProductThunk = (id: string) => {
	return async (dispatch: Dispatch<TProductActions>) => {
		try {
			const { data: product } = await productApi.getProduct(id);
			dispatch(setProductAction(product));
		} catch (err) {
			console.error(err);
			throw Error(err as string);
		}
	};
};
