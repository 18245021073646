import React from 'react';
import { useSelector } from 'react-redux';
import useResponsive from '../hooks/useResponsive';

import { instructionSelector, productSelectors } from '../store/selector/productSelectors';

import { Header, Sidebar, Backdrop, Dialog, Loader } from '../components';
import Footer from '../modules/Footer';
import MobileButtons from '../modules/MobileButtons/MobileButtons';

interface IProps {
	children?: React.ReactNode;
}

function Layout({ children }: IProps) {
	const { isMobile } = useResponsive();
	const product = useSelector(productSelectors);
	const instruction = useSelector(instructionSelector);
	let pageHeading = 'Работает на FASTEP';
	
	if (product && instruction) {
		const { brand, title: productName } = product;
		pageHeading = `Интерактивная инструкция ${brand?.title} ${productName}`
	}

	return (
		<>
			<h1 className="visually-hidden">{pageHeading}</h1>
			{!product ? (
				<div className='main-loader-wrapper'>
					<Loader />
				</div>
			) : (
				<main>
					<Header />
					<Sidebar />
					{children}
					{isMobile ? <MobileButtons /> : <Footer />}
					<Backdrop />
				</main>
			)}
			<Dialog />
		</>
	);
}

export default Layout;
