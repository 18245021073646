import { EScaleActions } from '../../enums/actions.enum';
import { IScaleState, TScaleActions } from '../../interfaces/scale';

const initialState: IScaleState = {
	scale: 1,
	stepScale: 0.15,
};

// eslint-disable-next-line default-param-last
export const scaleReducer = (state = initialState, action: TScaleActions): IScaleState => {
	switch (action.type) {
		case EScaleActions.setScale: {
			return { ...state, scale: action.payload };
		}
		case EScaleActions.setStepScale: {
			return { ...state, stepScale: action.payload };
		}
		default:
			return { ...state };
	}
};
