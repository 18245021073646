import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIndexInstructionAction, setStepAction } from '../../../store/actions/product';
import {
	instructionsSelector,
	indexInstructionSelector,
	currentIndexStepSelector,
} from '../../../store/selector/productSelectors';
import { sideBarIsOpenSelector } from '../../../store/selector/sidebarSelectors';
import { setIsOpenSidebarAction } from '../../../store/actions/sidebar';
import Collapse from '../../../components/Collapse';
import useResponsive from '../../../hooks/useResponsive';
import { useURLSearchParams } from '../../../hooks/useURLSearchParams';
import './Accordion.scss';

export default function Accordion() {
	const dispatch = useDispatch();

	const { instructionId: initialExpandedInstrId } = useURLSearchParams();
	const instructions = useSelector(instructionsSelector);
	const initialExpandedInstrIndex = instructions.findIndex(instr => instr.id === initialExpandedInstrId);
	const indexInstruction = useSelector(indexInstructionSelector);
	const currentStep = useSelector(currentIndexStepSelector);
	const sideBarIsOpen = useSelector(sideBarIsOpenSelector);

	const { isMobile } = useResponsive();

	useEffect(() => {
		if (initialExpandedInstrIndex !== -1) {
			dispatch(setIndexInstructionAction(initialExpandedInstrIndex));
		}
	}, [initialExpandedInstrIndex])

	const setInstruction = (index: number) => {
		if (indexInstruction !== index) {
			dispatch(setStepAction(0));
			dispatch(setIndexInstructionAction(index));
		}
	};

	const handleStepChange = (instrIndex: number, stepIndex: number) => {
		isMobile && dispatch(setIsOpenSidebarAction(!sideBarIsOpen));
		dispatch(setIndexInstructionAction(instrIndex));
		dispatch(setStepAction(stepIndex));
	};

	return (
		<>
			{instructions.map(({ id, title, steps }, instrIndex) => {
				return (
					<Collapse
						key={`${id}-instruction`}
						header={title}
						classNameMain={'accordion-item'}
						classNameHeader={'accordion-title'}
						arrowType={'default'}
						isOpenDefault={initialExpandedInstrIndex === instrIndex}
						onClick={() => {
							setInstruction(instrIndex);
						}}
					>
						{steps?.map(({ id: stepId, title: stepTitle }, stepIndex) => {
							return (
								<li
									key={stepId}
									className={stepIndex === currentStep && indexInstruction === instrIndex ? 'step-select' : ''}
									onClick={(e) => {
										e.stopPropagation();
										handleStepChange(instrIndex, stepIndex);
									}}
								>
									{stepTitle}
								</li>
							);
						})}
					</Collapse>
				);
			})}
		</>
	);
}
