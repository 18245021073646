import React from 'react';

export type TArrow = 'default' | 'primary' | 'red' | 'green' | 'blue';

export type TRotateArrow = 'top' | 'bottom' | 'right' | 'left';

interface IProps {
	rotate?: TRotateArrow;
	type?: TArrow;
}

function Index({ rotate = 'top', type = 'primary' }: IProps) {
	const getRotate = (variant: IProps['rotate']) => {
		switch (true) {
			case variant === 'top':
				return 'rotate(0deg)';
			case variant === 'bottom':
				return 'rotate(180deg)';
			case variant === 'right':
				return 'rotate(90deg)';
			case variant === 'left':
				return 'rotate(270deg)';
			default:
				return '';
		}
	};

	const getStyle = (variant: IProps['type']) => {
		const style = {
			fill: '#003ef9',
		};
		switch (true) {
			case variant === 'default':
				return { ...style, fill: '#130F26' };
			case variant === 'primary':
				return { ...style, fill: '#fff' };
			case variant === 'red':
				return { ...style, fill: '#ff0000' };
			case variant === 'blue':
				return { ...style, fill: '#003ef9' };
			case variant === 'green':
				return { ...style, fill: '#008000' };
			default:
				return style;
		}
	};

	return (
		<div>
			<svg
				style={{ transform: getRotate(rotate), transition: 'transform ease 200ms', ...getStyle(type) }}
				width="21"
				height="21"
				viewBox="0 0 21 21"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M10.1476 11.3094C10.3426 11.5031 10.6574 11.5031 10.8524 11.3094L15.2399 6.95025C15.6299 6.56278 16.2595 6.56278 16.6495 6.95025L16.786 7.08586C17.1796 7.4769 17.1796 8.11361 16.786 8.50465L11.2048 14.0497C10.8148 14.4372 10.1852 14.4372 9.79519 14.0497L4.21401 8.50465C3.82042 8.11361 3.82042 7.4769 4.21401 7.08586L4.3505 6.95025C4.74049 6.56278 5.37013 6.56278 5.76012 6.95025L10.1476 11.3094Z"
					fill={`${getStyle(type).fill}`}
				/>
			</svg>
		</div>
	);
}

export default Index;
