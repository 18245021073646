import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	instructionsSelector,
	indexInstructionSelector,
	currentIndexStepSelector,
	currentStepSelector,
} from '../../store/selector/productSelectors';
import { scaleSelectors, stepScaleSelector } from '../../store/selector/scaleSelectors';
import { setScaleAction } from '../../store/actions/scale';
import { isOpenIframeWindowSelector } from '../../store/selector/iframeWindowSelectors';

import { Loader, ButtonFrame } from '../index';

import { IPosition, ISize } from '../../interfaces/products';

import { getSizesAndPosition } from '../../utils/helper/getSizesAndPosition';
import { isiOS } from '../../utils/helper/isIOS';

import useDraggableScroll from '../../hooks/useDraggebleScroll';

function Content() {
	const dispatch = useDispatch();

	const currentIndexStep = useSelector(currentIndexStepSelector);
	const currentStep = useSelector(currentStepSelector);
	const currentIndexInstruction = useSelector(indexInstructionSelector);
	const instructions = useSelector(instructionsSelector);
	const scale = useSelector(scaleSelectors);
	const stepScale = useSelector(stepScaleSelector);
	const IframeWindowIsOpen = useSelector(isOpenIframeWindowSelector);

	const imgRef = useRef<HTMLImageElement | null>(null);
	const wrapperElRef = useRef<HTMLDivElement | null>(null);
	const mainElRef = useRef(null);
	const imgWrapperElRef = useRef<HTMLDivElement | null>(null);

	const [isLoadingImg, setIsLoadingImg] = useState<boolean>(true);
	const [isLoadedImage, setIsLoadedImage] = useState(false);

	const [imgSizes, setImgSizes] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
	const [buttonSizes, setButtonSizes] = useState<ISize>({ x: 0, y: 0 });
	const [buttonPosition, setButtonPosition] = useState<IPosition>({ x: 0, y: 0 });

	const imgUrl = currentStep?.button?.marker?.marker || '';

	const { onMouseDown } = useDraggableScroll(wrapperElRef);

	const colorMarkerFromApi =
		instructions[currentIndexInstruction]?.steps[currentIndexStep]?.button?.color || 'orange';

	// useLayoutEffect, потому что в iOS loadImageHandler выполняется перед useEffect.
	// а нам нужно, чтобы этот хук выполнился до loadImageHandler
	useLayoutEffect(() => {
		dispatch(setScaleAction(1));
		setIsLoadedImage(false);
		setIsLoadingImg(true);
	}, [imgUrl]);

	useEffect(() => {
		if (isLoadingImg) return;
		if (currentIndexStep !== 0 && !currentIndexStep) return;
		if (!imgRef.current) return;
		if (isLoadedImage) {
			const { button } = currentStep;
			if (!button?.size || !button?.position) return;
			const { size, position } = button;
			const sizesAndPosition = getSizesAndPosition({ position, size, scale });
			if (sizesAndPosition) {
				const { imgStyle, buttonPosition: btnPosition, buttonSize } = sizesAndPosition;
				setImgSizes(imgStyle);
				setButtonSizes(buttonSize);
				setButtonPosition(btnPosition);
			}
		}
	}, [isLoadedImage]);

	useEffect(() => {
		if (!isLoadedImage || !currentStep?.button?.position || !currentStep.button.size) return;
		const sizesAndPosition = getSizesAndPosition({
			position: currentStep.button.position,
			size: currentStep.button.size,
			scale,
		});
		if (sizesAndPosition) {
			const { buttonPosition: btnPosition, buttonSize } = sizesAndPosition;
			setButtonSizes(buttonSize);
			setButtonPosition(btnPosition);
		}
	}, [currentStep, isLoadedImage]);

	useEffect(() => {
		if (!isLoadedImage || !currentStep?.button?.position || !currentStep.button.size || !scale) return;
		const sizesAndPosition = getSizesAndPosition({
			position: currentStep.button.position,
			size: currentStep.button.size,
			scale: scale || stepScale,
		});
		if (sizesAndPosition) {
			const { imgStyle, buttonPosition: btnPosition, buttonSize } = sizesAndPosition;
			setImgSizes(imgStyle);
			setButtonSizes(buttonSize);
			setButtonPosition(btnPosition);
		}
	}, [scale]);

	function loadImageHandler() {
		setIsLoadingImg(false);
		setIsLoadedImage(true);
	}

	return (
		<div className="content" ref={mainElRef}>
			{IframeWindowIsOpen && (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions
				<div
					id={'wrapper'}
					className={isiOS() ? 'wrapper wrapper-for-ios' : 'wrapper'}
					ref={wrapperElRef}
					onMouseDown={(e) => onMouseDown(e)}
					onMouseMove={(e) => e.preventDefault()}
				>
					<div id={'scrollable'} className='scrollable' ref={imgWrapperElRef} style={imgSizes}>
						<img
							id={'general-image'}
							src={imgUrl}
							ref={imgRef}
							width={320}
							height={320}
							alt=''
							decoding={'sync'}
							loading={'eager'}
							onLoad={loadImageHandler}
							className='picture'
						/>
						{isLoadedImage && !isLoadingImg && (
							<ButtonFrame
								colorBorder={colorMarkerFromApi}
								size={buttonSizes}
								position={buttonPosition}
								scale={scale}
							/>
						)}
					</div>
					{(() => {
						switch (true) {
							case !isLoadedImage && isLoadingImg:
								return <Loader />;
							case !isLoadedImage:
								return <Loader />;
							default:
								return null;
						}
					})()}
				</div>
			)}
		</div>
	);
}

export default Content;
