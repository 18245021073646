import axios, { AxiosPromise } from 'axios';
import { globalHeaders } from './index';
import { IProduct } from '../interfaces/products';
import { ICreateEvaluateParams } from '../interfaces/productApi';

export default class ProductAPI {
	getProduct(id: string) {
		return axios({
			headers: globalHeaders,
			method: 'GET',
			url: `${process.env.REACT_APP_API}/catalog/product/${id}/details/`,
		}) as AxiosPromise<IProduct>;
	}

	createEvaluate({ productId, isUseful, comment }: ICreateEvaluateParams) {
		return axios({
			headers: globalHeaders,
			method: 'POST',
			url: `${process.env.REACT_APP_API}/catalog/product/${productId}/evaluate/`,
			data: {
				is_useful: isUseful,
				comment,
			},
		}) as AxiosPromise<IProduct>;
	}
}
