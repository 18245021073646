import { useDispatch, useSelector } from 'react-redux';
import { dialogSelectors } from '../../store/selector/dialogSelectors';
import { cancelDialogAction } from '../../store/actions/dialog';

function Backdrop() {
	const dispatch = useDispatch();
	const { visible } = useSelector(dialogSelectors);

	return visible ? (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className="modal-overlay"
			onClick={() => {
				dispatch(cancelDialogAction());
			}}
		/>
	) : null;
}
export default Backdrop;
