import React from 'react';

function useDraggableScroll(ref: React.RefObject<HTMLDivElement>) {
	const node = ref;

	if (process.env.NODE_ENV === 'development') {
		if (typeof node !== 'object' || typeof node.current === 'undefined') {
			console.error('useDraggableScroll expects a single ref argument.');
		}
	}

	let initialPosition = { scrollTop: 0, scrollLeft: 0, mouseX: 0, mouseY: 0 };

	const mouseMoveHandler = (event: MouseEvent): void => {
		if (!node.current) return;

		const { clientX } = event;
		const { clientY } = event;

		const diffX = clientX - initialPosition.mouseX;
		const diffY = clientY - initialPosition.mouseY;
		node.current.scrollTop = initialPosition.scrollTop - diffY;
		node.current.scrollLeft = initialPosition.scrollLeft - diffX;
	};

	const mouseUpHandler = () => {
		if (node.current) node.current.style.cursor = 'grab';

		document.removeEventListener('mousemove', mouseMoveHandler);
		document.removeEventListener('mouseup', mouseUpHandler);
	};

	const onMouseDown = (event: React.MouseEvent<HTMLDivElement>): void => {
		if (node.current) {
			const { clientX } = event;
			const { clientY } = event;

			initialPosition = {
				scrollLeft: node.current.scrollLeft,
				scrollTop: node.current.scrollTop,
				mouseX: clientX,
				mouseY: clientY,
			};

			node.current.style.cursor = 'grabbing';
			node.current.style.userSelect = 'none';

			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		}
	};
	return { onMouseDown };
}
export default useDraggableScroll;
