import { ESidebarActions } from '../../enums/actions.enum';
import { ISidebarState, TSidebarActions } from '../../interfaces/sidebar';

const initialState: ISidebarState = {
	isOpen: false,
};

// eslint-disable-next-line default-param-last
export const sidebarReducer = (state = initialState, action: TSidebarActions): ISidebarState => {
	switch (action.type) {
		case ESidebarActions.setIsOpenSidebar: {
			return { ...state, isOpen: action.payload };
		}
		default:
			return { ...state };
	}
};
