import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// Возвращает объект с параметрами поиска URL браузера
export const useURLSearchParams = () => {
  const { search } = useLocation();
	const queryString = search.substring(1);

  const params = useMemo(() => {
    const urlSearchParams = new URLSearchParams(queryString);
    return Object.fromEntries(urlSearchParams.entries()) as Partial<Record<string, string>>;  
  }, [queryString]);

	return params;
};
