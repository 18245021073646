export const copyToClipboard = (str: string): boolean => {
	let textarea;
	let result;

	try {
		textarea = document.createElement('textarea');
		textarea.setAttribute('readonly', '');
		textarea.setAttribute('contenteditable', '');
		textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
		textarea.value = str;

		document.body.appendChild(textarea);

		textarea.focus();
		textarea.select();

		const range = document.createRange();
		range.selectNodeContents(textarea);

		const sel = window.getSelection();
		if (sel) {
			sel.removeAllRanges();
			sel.addRange(range);
		}
		textarea.setSelectionRange(0, textarea.value.length);
		result = document.execCommand('copy');
	} catch (err) {
		console.error(err);
		result = null;
	} finally {
		if (textarea) document.body.removeChild(textarea);
	}

	// manual copy fallback using prompt
	if (!result) {
		const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
		const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
		// eslint-disable-next-line no-alert
		result = prompt(`Press ${copyHotkey}`, str);
		if (!result) {
			return false;
		}
	}
	return true;
};
