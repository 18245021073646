import { IDialogPayload, TDialogAction } from '../../interfaces/dialog';
import { EDialogActions } from '../../enums/actions.enum';

export const setDialogAction = (payload: IDialogPayload): TDialogAction => ({
	type: EDialogActions.SetDialog,
	payload,
});

export const cancelDialogAction = (): TDialogAction => ({
	type: EDialogActions.CancelDialog,
});
