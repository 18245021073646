import { EIframeWindowActions } from '../../enums/actions.enum';
import { IIframeWindowState, TIframeWindowActions } from '../../interfaces/iframeWindow';

const initialState: IIframeWindowState = {
	isOpen: false,
};

// eslint-disable-next-line default-param-last
export const iframeWindowReducer = (state = initialState, action: TIframeWindowActions): IIframeWindowState => {
	switch (action.type) {
		case EIframeWindowActions.setIsOpenIframeWindow: {
			return { ...state, isOpen: action.payload };
		}
		default:
			return { ...state };
	}
};
