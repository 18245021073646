import { AxiosError } from 'axios';

export const getErrorString = (
	errorObj: AxiosError,
	defaultStrError: string = 'Не удалось загрузить данные',
): string => {
	const { response } = errorObj;
	let errorMessage = defaultStrError;

	if (response?.data?.errors) {
		errorMessage = Object.values(response.data?.errors).join('\n');
	}

	return errorMessage;
};
