import React from 'react';
import { HashRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import store from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

if (process.env.NODE_ENV === 'production') {
	const tagManagerArgs = {
		gtmId: 'GTM-M9MMSL2',
	};

	TagManager.initialize(tagManagerArgs);
}


const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<HashRouter>
				<App />
			</HashRouter>
		</Provider>
	</React.StrictMode>,
);

reportWebVitals();
