import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sideBarIsOpenSelector } from '../../store/selector/sidebarSelectors';
import {
	currentIndexStepSelector,
	currentStepSelector,
	indexInstructionSelector,
	instructionsSelector,
} from '../../store/selector/productSelectors';
import { setStepAction } from '../../store/actions/product';
import { scaleSelectors, stepScaleSelector } from '../../store/selector/scaleSelectors';
import { setScaleAction } from '../../store/actions/scale';

import { Button } from '../../components';

import { scaleZoom } from '../../mockData/scaleZoom';

import './Footer.scss';

function Footer() {
	const dispatch = useDispatch();

	const sidebarIsOpen = useSelector(sideBarIsOpenSelector);
	const step = useSelector(currentStepSelector);
	const indexStep = useSelector(currentIndexStepSelector);
	const indexInstruction = useSelector(indexInstructionSelector);
	const instructions = useSelector(instructionsSelector);
	const scale = useSelector(scaleSelectors);
	const stepScale = useSelector(stepScaleSelector);

	const lengthSteps = instructions.length ? instructions[indexInstruction].steps.length : 0;

	const prevStepHandler = () => dispatch(setStepAction(indexStep - 1));
	const nextStepHandler = () => dispatch(setStepAction(indexStep + 1));

	const zoomIncHandler = () => {
		if (scale < scaleZoom.max) {
			dispatch(setScaleAction(scale + stepScale));
		}
	};

	const zoomDecHandler = () => {
		if (scale > stepScale) {
			dispatch(setScaleAction(scale - stepScale));
		}
	};

	return (
		<footer className={`footer${sidebarIsOpen ? ' sidebar-is-open' : ''}`}>
			<div className='step-counter'>
				<div className='counter'>
					{indexStep + 1} / {instructions.length ? lengthSteps : 0}
				</div>
				<Button className='btn-prev' onClick={prevStepHandler} disabled={indexStep === 0} />
				<Button
					className='btn-next'
					onClick={nextStepHandler}
					disabled={instructions.length ? indexStep + 1 === lengthSteps : true}
				/>
			</div>
			<h2 className='step-title'>{step?.title}</h2>
			<div className='btns-group'>
				<Button className='btn-plus' onClick={() => zoomIncHandler()} />
				<Button className='btn-minus' onClick={() => zoomDecHandler()} />
			</div>
		</footer>
	);
}

export default Footer;
