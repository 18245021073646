export interface IObjectQueries {
	[key: string]: string;
}
export default function getQueryToObject<T = IObjectQueries>(queryParams: string): IObjectQueries | T {
	const objectQueries: IObjectQueries | T = {};
	new URLSearchParams(queryParams).forEach((value, key) => {
		objectQueries[key] = value;
	});

	return objectQueries;
}
