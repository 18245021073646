import { useDispatch, useSelector } from 'react-redux';

import {
	currentIndexStepSelector, currentStepSelector,
	indexInstructionSelector, instructionSelector, instructionsSelector,
	productSelectors,
} from '../../store/selector/productSelectors';
import { cancelDialogAction, setDialogAction } from '../../store/actions/dialog';
import { setIsOpenIframeWindowAction } from '../../store/actions/iframeWindow';
import { setIsOpenSidebarAction } from '../../store/actions/sidebar';
import { sideBarIsOpenSelector } from '../../store/selector/sidebarSelectors';

import { Button } from '../../components';
import { YaShare } from '../../components/YaShare';
import FeedbackModalContent from '../FeedbackModalContent';

import { IDialogPayload } from '../../interfaces/dialog';

import { exitFullscreen } from '../../utils/helper/exitFullscreen';
import { isiOS } from '../../utils/helper/isIOS';

import useResponsive from '../../hooks/useResponsive';

import './Header.scss';

import logoSvg from '../../assets/img/logo_fastep.svg';

function Header() {
	const dispatch = useDispatch();

	const product = useSelector(productSelectors);
	const step = useSelector(currentStepSelector);
	const indexStep = useSelector(currentIndexStepSelector);
	const instructionsList = useSelector(instructionsSelector);
	const indexInstruction = useSelector(indexInstructionSelector);
	const isOpen = useSelector(sideBarIsOpenSelector);
	const instruction = useSelector(instructionSelector);

	const lengthSteps = instructionsList.length ? instructionsList[indexInstruction].steps.length : 0;

	const { isMobile } = useResponsive();

	function showModalHandler({ content, titleText, activeClass }: IDialogPayload) {
		dispatch(setDialogAction({ content, titleText, activeClass }));
	}

	const exitFullScreen = () => {
		dispatch(cancelDialogAction());
		exitFullscreen();
		dispatch(setIsOpenIframeWindowAction(false));
		if (isiOS()) {
			window.close();
		}
	}

	const handleLeaveReviewClick = () => {
		showModalHandler({
			content: <FeedbackModalContent />,
			titleText: 'Помогла ли вам инструкция?',
			activeClass: 'modal-close-vidget',
		})
	};

	if (!product) {
		return null;
	}

	const { category, image, brand, title } = product;
	const imageAlt = `${brand?.title} ${title}`

	return (
		<header className="header">
			{isMobile && (
				<Button
					className="btn-sidebar"
					onClick={() => {
						dispatch(setIsOpenSidebarAction(!isOpen));
					}}
				/>
			)}

			{isMobile && !isOpen ? (
				<div className="btns-mobile-group">
					<h2 className="step-title" title={step?.title}>
						<span className="counter">
							{indexStep + 1} / {instructionsList.length ? lengthSteps : 0}
						</span>
						{step?.title}
					</h2>
				</div>
			) : (
				<div className="bread-crumbs">
					<img src={image?.url} className="preview" alt={imageAlt} />
					<h2>
						<span className="titles-group">
							<span>Интерактивная инструкция</span>
							{category?.title} {brand?.title} {title}
						</span>
						<span className="title only-desktop">{instruction?.title}</span>
					</h2>
				</div>
			)}

			<div className="btns-group">
				{!isMobile && (
					<Button className="btn-modal" onClick={handleLeaveReviewClick}>
						Оставить отзыв
					</Button>
				)}

				<YaShare />

				<a href="https://fastep.io/" className="logo-fastep-link" target="_blank" rel="nofollow">
					<img src={logoSvg} className="logo-fastep" alt="" />
				</a>

				{/* Проверка открыт виджет в iFrame или в новом окне */}

				<Button
					className={`btn-close ${window.location !== window.parent.location ? '' : 'btn-close-mobile'}`}
					onClick={exitFullScreen}
				/>
			</div>
		</header>
	);
}

export default Header;
