import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogAction } from './store/actions/dialog';
import { getProductThunk } from './store/actions/product';
import { sideBarIsOpenSelector } from './store/selector/sidebarSelectors';
import { setIsOpenSidebarAction } from './store/actions/sidebar';
import { setIsOpenIframeWindowAction } from './store/actions/iframeWindow';
import { loadYaShareScriptThunk } from './store/actions/yaShare';
import Layout from './layout';
import { Content } from './components';
import getQueryToObject from './utils/helper/getQueryToObject';
import { getErrorString } from './utils/helper/getErrorString';
import { exitFullscreen } from './utils/helper/exitFullscreen';
import { useSetDocumentTitle } from './hooks/useSetDocumentTitle';

function App() {
	const dispatch = useDispatch();
	const location = useLocation();

	const sidebarIsOpen = useSelector(sideBarIsOpenSelector);

	useSetDocumentTitle();

	dispatch(loadYaShareScriptThunk());

	useEffect(() => {
		const exitListener = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				exitFullscreen();
				dispatch(setIsOpenIframeWindowAction(false));
			}
		};
		const resizeListener = (e: UIEvent) => {
			const wTarget = e.currentTarget as Window;

			if (!wTarget.innerHeight && !wTarget.innerWidth) {
				dispatch(setIsOpenIframeWindowAction(false));
			} else {
				dispatch(setIsOpenIframeWindowAction(true));
			}
		};

		document.addEventListener('keyup', exitListener);
		window.addEventListener('resize', resizeListener);

		if (!window.innerHeight && !window.innerWidth) {
			dispatch(setIsOpenIframeWindowAction(false));
		} else {
			dispatch(setIsOpenIframeWindowAction(true));
		}

		async function handle() {
			try {
				const productId = getQueryToObject(location.search)?.productId;
				if (!productId) {
					throw Error('Не productId!');
				}
				await dispatch(getProductThunk(productId));
			} catch (err) {
				dispatch(
					setDialogAction({ titleText: 'Произошла ошибка!', content: getErrorString(err as AxiosError) }),
				);
			}
		}
		handle();

		return () => {
			document.removeEventListener('keyup', exitListener);
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	return (
		<Layout>
			<Content />
			<div
				onClick={() => {
					dispatch(setIsOpenSidebarAction(false));
				}}
				tabIndex={-1}
				role={'button'}
				className={`black-overlay ${sidebarIsOpen ? 'black-overlay-visible' : ''}`}
			/>
		</Layout>
	);
}

export default memo(App);
