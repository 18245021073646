import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productSelectors } from '../../store/selector/productSelectors';
import Button from '../../components/Button';
import { productApi } from '../../api';
import './feedbackModalContent.scss';
import { cancelDialogAction, setDialogAction } from '../../store/actions/dialog';

function FeedbackModalContent() {
	const dispatch = useDispatch();

	const product = useSelector(productSelectors);

	const [isVisibleTextarea, setIsVisibleTextarea] = useState(false);
	const [valTextarea, setValTextarea] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	function handleNo() {
		if (!product) return;
		setIsVisibleTextarea(true);
	}

	function showThankfulness() {
		dispatch(
			setDialogAction({
				content: 'Благодарим за отзыв!',
				activeClass: 'thankfulness-modal',
			}),
		);
	}

	async function handleYes() {
		if (!product) return;
		try {
			setIsLoading(true);
			await productApi.createEvaluate({
				productId: product.id,
				isUseful: true,
			});
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoading(false);
			dispatch(cancelDialogAction());
			showThankfulness();
		}
	}

	async function sendFeedback() {
		if (!product || !valTextarea) return;
		try {
			setIsLoading(true);
			await productApi.createEvaluate({
				productId: product.id,
				isUseful: false,
				comment: valTextarea,
			});
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoading(false);
			dispatch(cancelDialogAction());
			showThankfulness();
		}
	}

	return (
		<div>
			{isVisibleTextarea ? (
				<div className="sending-wrapper">
					<div className="sending-wrapper-title">Что мы можем улучшить?</div>
					<textarea
						className="feedback-textarea"
						value={valTextarea || undefined}
						onChange={(e) => {
							setValTextarea(e.target.value);
						}}
						maxLength={255}
					/>
					<Button
						onClick={() => {
							sendFeedback();
						}}
						disabled={!valTextarea || isLoading}
						className="btn-modal btn-send"
					>
						Отправить
					</Button>
				</div>
			) : (
				<div className="btns-group">
					<button
						className="btn-modal btn-white"
						onClick={handleNo}
						disabled={isLoading}
						style={{ marginRight: 21 }}
					>
						Нет
					</button>
					<button className="btn-modal btn-white" onClick={handleYes} disabled={isLoading}>
						Да
					</button>
				</div>
			)}
		</div>
	);
}

export default FeedbackModalContent;
