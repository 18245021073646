import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './YaShare.module.scss';

import { setDialogAction } from '../../store/actions/dialog';
import { YaShareDialogContent } from './components/YaShareDialogContent';
import { RootState } from '../../store/rootReducer';

export function YaShare() {
	const dispatch = useDispatch();
  const isYaShareLoaded = useSelector((state: RootState) => state.yaShare.ready);
  
	const btnRef = useRef(null);

	const handleShareButtonClick = () => {
    const dialogOptions = {
      content: <YaShareDialogContent />,
      titleText: 'Поделиться',
      activeClass: 'modal-share',
    };

		dispatch(
			setDialogAction(dialogOptions),
		);
	};

	return (
		<button
			ref={btnRef}
			className={styles.root}
			onClick={handleShareButtonClick}
			disabled={!isYaShareLoaded}
			aria-label="Поделиться"
		/>
	);
}
