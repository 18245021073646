import React, { useEffect, useCallback, useState } from 'react';

function getWindowDimension() {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	return { width, height };
}

function checkIsMobile(width: number) {
	return width <= 979;
}

export default function useResponsive(viewport = false) {
	const [isMobile, setIsMobile] = useState<boolean | null>(null);
	const [viewportHeight, setViewportHeight] = useState(0);
	const [viewportWidth, setViewportWidth] = useState(0);

	const handleResizeWindow = useCallback(() => {
		const { width, height } = getWindowDimension();
		const computeResult = checkIsMobile(width);
		if (computeResult !== isMobile) setIsMobile(computeResult);
		if (viewport) {
			setViewportHeight(height);
			setViewportWidth(width);
		}
	}, [isMobile, viewportHeight, viewportWidth]);

	useEffect(() => {
		window.addEventListener('resize', handleResizeWindow);
		return () => {
			window.removeEventListener('resize', handleResizeWindow);
		};
	}, [handleResizeWindow]);

	React.useEffect(() => {
		const { width, height } = getWindowDimension();
		setIsMobile(checkIsMobile(width));
		if (viewport) {
			setViewportHeight(height);
			setViewportWidth(width);
		}
	}, []);

	return viewport ? { isMobile, viewportWidth, viewportHeight } : { isMobile };
}
