import React, { useEffect, useState } from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import classNames from 'classnames';
import Arrow, { TArrow } from '../Arrow';
import './Collapse.scss';

interface ICollapse {
	children: React.ReactNode;
	header?: string | React.ReactNode;
	onCloseHandler?: () => void;
	isOpen?: boolean;
	isOpenDefault?: boolean;
	setIsOpen?: (isOpen: boolean) => void;
	classNameHeader?: string;
	classNameMain?: string;
	arrowType?: TArrow;
	isHeader?: boolean;
	blocked?: boolean;
	onClick?: () => void;
}

function Collapse({
	children,
	header,
	onCloseHandler,
	classNameHeader,
	classNameMain,
	arrowType,
	isOpen,
	isOpenDefault,
	setIsOpen,
	isHeader = true,
	blocked,
	onClick,
}: ICollapse) {
	const [isOpened, setIsOpened] = useState(isOpenDefault || false);
	const cnHeader = classNames({ 'collapse-header': true });
	const cnMainCollapse = classNames({ collapse: true });
	function isOpenHandel() {
		onClick && onClick();
		!blocked && setIsOpened(!isOpened);
	}

	useEffect(() => {
		!isOpened && onCloseHandler && onCloseHandler();
		setIsOpen && setIsOpen(isOpened);
	}, [isOpened]);

	return (
		<div className={`${cnMainCollapse} ${classNameMain}`}>
			{isHeader ? (
				<div className={isOpened ? `${cnHeader} ${classNameHeader} accordion-active` : `${cnHeader} ${classNameHeader}`} onClick={isOpenHandel} role="presentation">
					<p className="collapse-header-title">{header}</p>
					<div className="collapse-header-arrow">
						<Arrow rotate={isOpened ? 'top' : 'left'} type={arrowType} />
					</div>
				</div>
			) : (
				''
			)}
			<ReactCollapse isOpened={isOpen !== undefined ? isOpen : isOpened}>
				<ol className="collapse-children">{children}</ol>
			</ReactCollapse>
		</div>
	);
}

export default Collapse;
