import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as Icon } from '../../../../assets/img/btn_link.svg';
import { copyToClipboard } from '../../../../utils/helper/copyToClipboard';

import styles from './YaShareDialogContent.module.scss';

enum SocialService {
  Telegram = 'telegram',
  Whatsapp = 'whatsapp',
  YandexMessenger = 'messenger',
  Vkontakte = 'vkontakte',
  Odnoklassniki = 'odnoklassniki',
}

export function YaShareDialogContent() {
  const yaContainerRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
	const sharedLink = window.location.href;

	useEffect(() => {
		if (!isCopied) return;

    setTimeout(() => {
			setIsCopied(false);
		}, 500);
	}, [isCopied]);

	const copyHandler = () => {
		if (copyToClipboard(sharedLink)) {
			setIsCopied(true);
		}
	};

  useEffect(() => {
    const container = yaContainerRef.current;
    const yaShareInit = window.Ya?.share2;
    if (!yaShareInit || !container) return;

    const services = [
      SocialService.Telegram,
      SocialService.Whatsapp,
      SocialService.Vkontakte,
      SocialService.Odnoklassniki,
    ].join(',');
    
    const share = yaShareInit(container, {
      content: {
          url: sharedLink,
          title: document.title,
          description: 'Работает на FASTEP',
      },
      theme: {
        lang: 'ru',
        services,
        size: 'l',
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      share?.destroy();
    };
  }, []);
  
  return (
    <div>
      <div className={styles.yaContainer} ref={yaContainerRef} />
      
      <button
				className={cn(styles.copyBtn, isCopied && styles.copyBtnCopied)}
				onClick={copyHandler}
        disabled={isCopied}
			>
        <Icon />
				{isCopied ? 'Скопировано' : 'Копировать ссылку'}
			</button>
    </div>
  );
}
