/* eslint-disable no-shadow */

export const enum EDialogActions {
	SetDialog = 'SET_DIALOG',
	CancelDialog = 'CANCEL_DIALOG',
}

export const enum EProductActions {
	fetchProduct = 'FETCH_PRODUCT',
	setProductError = 'SET_PRODUCT_ERROR',
}

export const enum EAccordionActions {
	setStep = 'SET_STEP',
	setIndexInstruction = 'SET_INDEX_INSTRUCTION',
	setInstruction = 'SET_INSTRUCTION',
}

export const enum ESidebarActions {
	setIsOpenSidebar = 'SET_IS_OPEN_SIDEBAR',
}

export const enum EScaleActions {
	setScale = 'SET_SCALE',
	setStepScale = 'SET_STEP_SCALE',
}

export const enum EIframeWindowActions {
	setIsOpenIframeWindow = 'SET_IS_OPEN_IFRAME_WINDOW',
}

export enum EYaShareActions {
	SetYaShareReady = 'SET_YA_SHARE_READY',
	SetYaShareLoadError = 'SET_YA_SHARE_LOAD_ERROR',
}
