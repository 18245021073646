import { IPosition, ISize } from '../../interfaces/products';

interface IParams {
	position: IPosition;
	size: ISize;
	scale?: number;
}

interface IReturn {
	imgStyle: { width: number; height: number; top?: number };
	buttonPosition: IPosition;
	buttonSize: ISize;
}

export function getSizesAndPosition({ position, size, scale }: IParams): IReturn | null {
	const wrapper = (document.getElementById('wrapper') as HTMLDivElement) || undefined;
	const scrollable = (document.getElementById('scrollable') as HTMLDivElement) || undefined;
	const image = (document.getElementById('general-image') as HTMLImageElement) || undefined;
	const footerHeight = 114;
	if (!wrapper || !scrollable || !image) {
		console.error('Error! !wrapper || !scrollable || ! image getInitImgSizes()');
		return null;
	}

	const wrapperHeight = wrapper.offsetHeight;
	const wrapperWidth = wrapper.offsetWidth;

	const imgHeightInit = image.naturalHeight;
	const imgWidthInit = image.naturalWidth;

	const ratio = Math.min(wrapperWidth / imgWidthInit, (wrapperHeight - footerHeight) / imgHeightInit);

	let imgWidth = imgWidthInit * ratio;
	let imgHeight = imgHeightInit * ratio;
	let imgTop;

	const buttonPosition = { x: position.x * ratio, y: position.y * ratio };
	const buttonSize = { x: size.x * ratio, y: size.y * ratio };

	if (scale) {
		imgWidth *= scale;
		imgHeight *= scale;
		buttonPosition.x *= scale;
		buttonPosition.y *= scale;
		buttonSize.x *= scale;
		buttonSize.y *= scale;
		if (imgHeight > wrapperHeight) {
			imgTop = undefined;
		} else {
			imgTop = wrapperHeight / 2 - imgHeight / 2;
		}
	}

	return {
		imgStyle: {
			width: imgWidth || 0,
			height: imgHeight || 0,
			top: imgTop || 0,
		},
		buttonPosition,
		buttonSize,
	};
}
