import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sideBarIsOpenSelector } from '../../store/selector/sidebarSelectors';
import { setDialogAction } from '../../store/actions/dialog';
import { setIsOpenSidebarAction } from '../../store/actions/sidebar';
import { Accordion, Button } from '../../components';
import { YaShare } from '../../components/YaShare';
import FeedbackModalContent from '../FeedbackModalContent';
import useResponsive from '../../hooks/useResponsive';
import logoSvg from '../../assets/img/logo_fastep.svg';
import './Sidebar.scss';

function Sidebar() {
	const dispatch = useDispatch();
	const isOpen = useSelector(sideBarIsOpenSelector);
	const { isMobile } = useResponsive();

	useEffect(() => {
		dispatch(setIsOpenSidebarAction(true));
	}, []);

	return (
		<div className="sidebar-wrapper">
			{!isMobile && (
				<Button
					className={`btn-sidebar ${isOpen ? 'btn-sidebar-expanded' : ''}`}
					onClick={() => {
						dispatch(setIsOpenSidebarAction(!isOpen));
					}}
				/>
			)}

			<aside className={`scrollbar ${isOpen ? 'sidebar-expanded' : ''} sidebar`}>
				<nav className="navigation">
					<h2 className="only-desktop">Выберите инструкцию</h2>
					<div className="btns-header">
						{isMobile && (
							<>
								<Button
									className="btn-modal"
									onClick={() =>
										dispatch(
											setDialogAction({
												content: <FeedbackModalContent />,
												titleText: 'Помогла ли вам инструкция?',
												activeClass: 'modal-close-vidget',
											}),
										)
									}
								>
									Оставить отзыв
								</Button>

								<YaShare />
								
								<a href="https://fastep.io/" className="logo-fastep-link" target="_blank" rel="nofollow"><img src={logoSvg} className="logo-fastep" alt="" /></a>
							</>
						)}
					</div>
					<Accordion />
				</nav>
			</aside>
		</div>
	);
}

export default Sidebar;
